import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalHttpInterceptorService } from './interceptors/jwt.interceptor';

import { SurveyModule } from "survey-angular-ui";

import { NgxDropzoneModule } from 'ngx-dropzone';



@NgModule({
  declarations: [AppComponent, ],
  imports: [CommonModule, BrowserModule,FormsModule, IonicModule.forRoot(), IonicStorageModule.forRoot(),    AppRoutingModule, HttpClientModule, SurveyModule, NgxDropzoneModule,],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy},{ provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {}
