import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from './services/api.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  currentRoute: any;
  showMenu: boolean = true;
  showSubMenu: boolean = true;
  showActMenu: boolean = true;
  showHcaActMenu: boolean = false;

  constructor(private router: Router, public apiService: ApiService) {
    if(apiService.currentUser.roles && apiService.currentUser.roles.includes('- health_agent')) {
      console.log("isHca=true");
      apiService.isHca = true;
    }
    console.log(apiService.currentUser.paid);
    
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        let ev: any = event;
        this.currentRoute = ev.url;
        //console.log(this.currentRoute);
        console.log(apiService.currentUser);
        if (this.currentRoute.includes("sign-up")) this.showMenu = false;
        if (this.currentRoute.includes("sign-in")) this.showMenu = false;
        if (this.currentRoute.includes("forgot-password")) this.showMenu = false;

        if (!this.currentRoute.includes("forms")) this.showSubMenu = false;
        if (!this.currentRoute.includes("account")) this.showActMenu = false;
        if (!this.currentRoute.includes("hca/")) this.showHcaActMenu = false;
      });
  }

  toggleSubMenu() {
    if (this.showSubMenu) this.showSubMenu = false; else this.showSubMenu = true;
  }
  toggleActMenu() {
    if (this.showActMenu) this.showActMenu = false; else this.showActMenu = true;
  }
  toggleHcaActMenu() {
    if (this.showHcaActMenu) this.showHcaActMenu = false; else this.showHcaActMenu = true;
  }


}
