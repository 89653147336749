import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'document',
    loadChildren: () => import('./document/document.module').then( m => m.DocumentPageModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('./forms/forms.module').then( m => m.FormsPageModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./faqs/faqs.module').then( m => m.FaqsPageModule)
  },
  {
    path: 'subusers',
    loadChildren: () => import('./subusers/subusers.module').then( m => m.SubusersPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'account/profile',
    loadChildren: () => import('./my-account/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'account/billing',
    loadChildren: () => import('./my-account/billing/billing.module').then( m => m.BillingPageModule)
  },
  {
    path: 'hca/hcaprofile',
    loadChildren: () => import('./my-hca-account/hcaprofile/hcaprofile.module').then( m => m.HcaprofilePageModule)
  },
  {
    path: 'hca/hcabilling',
    loadChildren: () => import('./my-hca-account/hcabilling/hcabilling.module').then( m => m.HcabillingPageModule)
  },
  {
    path: 'hca/hcausers',
    loadChildren: () => import('./my-hca-account/hcausers/hcausers.module').then( m => m.HcausersPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}