import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public currentToken: any = "";
  public currentUser: any = null;
  public _isLoggedIn = new BehaviorSubject<boolean>(false);
  isHca: boolean = false;

  public thisLoading:any

  constructor(private http: HttpClient, public loadingController: LoadingController, public alertController: AlertController, public toastController:ToastController) {
    this.getLogin();
  }

async showLoader() {
  const loading = await this.loadingController.create();
  await loading.present();
  return loading;
}

async hideLoader(specificLoader:any) {
  await specificLoader.dismiss();
}

async showAlert(title:string, msg:string) {
/*const alert = await this.alertController.create({
  header: title,
  message: msg,
  buttons: [{text: 'OK', handler: null}],
});
await alert.present();
*/
return this.alertController.create({
  header: title,
  message: msg,
  buttons: [
    {
      text: 'Ok',
      role: 'cancel',
    },
  ],
})
.then((alertEl) => {
  alertEl.present();
  return alertEl.onDidDismiss();
});

}

async showToast(msg:string){
    const toast = await this.toastController.create({
      message: msg,
      duration: 1500,
      position: 'top'
    });
    await toast.present();
}

get isLoggedIn(): Observable<boolean> {
  return this._isLoggedIn.asObservable();
}


  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError("Something bad happened; please try again later.");
  }
  
  async setLogin() {
    localStorage.setItem('token', this.currentToken);
    localStorage.setItem('user', JSON.stringify(this.currentUser));
    if (this.currentToken != null && this.currentToken != "") this._isLoggedIn.next(true); else this._isLoggedIn.next(false);
  }
  async getLogin() {
    this.currentToken = localStorage.getItem('token');
    this.currentUser = JSON.parse(localStorage.getItem('user') || '{}');
    if (this.currentToken != null && this.currentToken != "") this._isLoggedIn.next(true); else this._isLoggedIn.next(false);
    //console.log("got login:" + this.currentToken);
  }
  async clearLogin() {
    await localStorage.clear();
    this.currentToken= undefined;
    this.currentUser = undefined; 
    this._isLoggedIn.next(false);
  }

  register(body:any) {
    //var signUpInfo = { email: email, password: password, firstName: firstName, lastName: lastName };
    return this.http.post<any>(environment.apiUrl + "/auth/signup", body)
      .pipe(
        map(response => {
          return response;
        }));
  }


  checkLogin(email: string, password: string) {
    var signInInfo = { email: email, password: password };
    return this.http.post(environment.apiUrl + "/auth/signin", signInInfo)
      .pipe(
        map(response => {
          let resp:any= response;
          this.currentUser = resp.user;
          this.currentToken = resp.accessToken;
          this.setLogin();
          return response;
        }));
  }



  getApi(url:string) {
    return this.http.get<any>(environment.apiUrl + url, { headers: { 'Authorization': 'Bearer ' + this.currentToken } })
      .pipe(
        map(response => {
          return response;
        }));
  }
  postApi(url:string,body:any) {
    return this.http.post<any>(environment.apiUrl + url, body, { headers: { 'Authorization': 'Bearer ' + this.currentToken } })
      .pipe(
        map(response => {
          return response;
        }));
  }

  getMasters(table: string) {
    return this.http.get<any>(environment.apiUrl + "/masters/" + table)
      .pipe(
        map(response => {
          return response;
        }));
  }


  uploadFile(formData:FormData){
    return this.http.post(environment.apiUrl + "/uploadFile", formData, { headers: { 'Authorization': 'Bearer ' + this.currentToken } })
    .pipe(
      map(response => {
        return response;
      }));
  }
  
 
}
